<template>
  <MenuForm
    :breadcrumbs="breadcrumbs"
    :value="{...defaultNew, menuCategories: []}"
  >
    <template #actions="{form, fields}">
      <v-btn
        :disabled="!form"
        color="success"
        class="mx-2"
        rounded
        @click="create(fields)"
      >
        Create
      </v-btn>
    </template>
  </MenuForm>
</template>

<script>
import MenuForm from '@/pages/App/MenuCreator/Menus/__MenuForm__'
import { Menu } from '@/store/models'
import { mapGetters } from 'vuex'
import createMixin from '@/mixins/apiCreateMixin'

export default {
  name: 'MenuNew',
  components: { MenuForm },
  mixins: [createMixin(Menu)],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Menus',
          exact: true,
          to: { name: 'App-MenuCreator-Menus' }
        },
        {
          text: 'New',
          exact: true,
          to: { name: 'App-MenuCreator-Menus-New' }
        }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['defaultNew'])
  }
}
</script>
